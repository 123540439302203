var NotificationModule = function () {
    var self = this;
    var isMobile = false;
    if( /android|webos|iphone|ipad|ipod|blackBerry|iemobile|opera Mini/i.test(navigator.userAgent.toLowerCase()) ) {
        isMobile = true;
    }
    var socket
    self.socket = null
    const options_default = {
        title: 'Platerate',
        icon: '/images/banner_platerate.png',
        body: null,
        onclick: function (e) {}, // e -> Notification object
        onclose: function (e) {},
        ondenied: function (e) {}
    }
    var Notification = '';
    self.init = function () {
        document.addEventListener('DOMContentLoaded', function () {
            if (!Notification) {
                console.log('Desktop notifications not available in your browser');
                return;
            }

            // if (Notification.permission !== "granted")
                // Notification.requestPermission();
        });

        socket = io.connect(window.location.origin, {
            transports: ['websocket' /*, 'polling'*/ ],
            query: {
                userId: $('#userId').val()
            },
            reconnection: true
        });
        self.socket = socket

        socket.emit('check_connect', null, function (msg) {})

        socket.on('check_connect', function (msg) {
           console.info("mesg===",msg);
        })

        socket.on('disconnect', function (msg) {
            console.info("Socket is disconnected");
        })

        socket.on('notify', function (msg) {
            console.log("- - - - --  - -notify");
            if(window.location.href.match(/.+rate\/order\/\d+/)) {
                if (msg.type == "alert-to-user"){
                    AlertToUserRateOrder(msg.data)
                }
                //return true
            }
            
            console.log(msg);
            if (msg.type == "join-order-request"){
              OrderModule.pushJoinOrderNotification(msg.targetVenueId, msg.data, msg.data.checkout, msg.data.orderType, msg.data.targetOrderId);
            } else if (msg.type == "join-order-request-approved"){
              OrderModule.pushJoinOrderApprovedNotification(msg.targetVenueId, msg.data);
            } else if (msg.type == "join-order-update-type"){
              OrderModule.pushJoinOrderUpdateType(msg.targetVenueId, msg.data);
            } else if (msg.type == "join-order-checkout-update"){
              OrderModule.pushJoinOrderCheckoutUpdate(msg.targetVenueId, msg.data);
            } else if (msg.type == "refresh-in-cart"){
              OrderModule.pushRefreshIncartOrder(msg.targetVenueId, msg.data);
            } else if (msg.type == "update-joinable-orders"){
                OrderModule.update_joinable_orders(msg.targetVenueId, msg.data);
            }
            else if (msg.type == "alert-to-user"){
                OrderModule.AlertToUser(msg.data);
            } else if (msg.type =='refund-order-paid'){
                OrderModule.refreshCancelItemOrder(msg.targetVenueId, msg.data)
            } else if (msg.type =='order-ahead-invitation'){
                OrderModule.pushOrderAheadInvitationNotification(msg.targetVenueId, msg.data)
            } else if(msg.type =='refresh-restaurant-orders') {
                OrderRestaurant.refreshNewOrder(msg.targetVenueId);
            } else if(msg.type =='person-contact') {
                OrderModule.pushDeliveryPhoneNumber(msg);
            } else if(msg.type =='confirm-delivered-order') {
                OrderModule.pushConfirmDeliveredOrder(msg);
            } else if(msg.type =='changed-order-time') {
                console.log("hhh====")
                OrderRestaurant.pushChangeOrderTime(msg);
            }

             else if(msg.type =='change-time-reserve') {
                OrderModule.pushChangeTimeReserveOrderAhead(msg);
            // } else if(msg.type =='order-alarm-for-restaurant') {
            //     console.log("-----------------SOCKET CATCHED");
            //     OrderRestaurant.pushOrderAlarm(msg);
            } else if(msg.orderVenueId) {
                if ( window.location.href.toString().match(/restaurant/i) && typeof OrderRestaurant != "undefined" && typeof OrderRestaurant.refreshNewOrder  == 'function') {
                    OrderRestaurant.refreshNewOrder(msg, self.notify);
                } else {
              self.notify({
                title: msg.title,
                body: msg.body,
                onclick: function () {
                  window.open(msg.link)
                }
              });
		}
            } else {
              self.notify({
                title: msg.title,
                body: msg.body,
                onclick: function () {
                  window.open(msg.link)
                }
              });
            }
        });

        socket.on('has-new-order', function (data){
            OrderModule.has_new_orders(data)
        })

        socket.on('update-order-state', function (data){
            if (window.location.href.match(/^.+rate\/order\/\d+$/)) {
              $('.order-update-target').trigger('order-notification', data);
              // update_order_state_bar(data);
            }
        })

        socket.on('reload-order-restaurant', function (data){
            if(typeof(getRestaurantOrders) === "function") {
                getRestaurantOrders();
            };
        })

    }


    self.check_connect = function () {
      return new Promise(function(resolve, reject){
          let result = null
          self.socket.emit('check_connect', null, function (res){
            resolve(res.status)
          })
      });
    }

    self.notify = function (_options) {
        if(isMobile) {
            // swal({
            //     title: "Notification",
            //     text: _options.body,
            //     type: "info",
            //     allowOutsideClick: true,
            //     confirmButtonText: "Ok",
            //     confirmButtonColor: "#3cb371"
            // }, function(comfirn) {
            //     _options.onclick();
            // });
        } else {
            // if (Notification.permission !== "granted")
                // Notification.requestPermission();
            // else {
                var options = Object.assign(options_default, _options);
                var notification = new Notification(options.title, {
                    icon: options.icon,
                    body: options.body
                });

                notification.onclick = options.onclick;
            // }
        }
    }
}

/**
 * Notification
 */
$(function () {
    function pushLocationNotify(lat, lng) {
        if(lat && lng){
            const loc = {
                lat: lat,
                lng: lng
            };
            // ajax push location
            $.ajax({
                url: '/notification/pushlocationnotification',
                method: 'POST',
                data: JSON.stringify({loc: loc}),
                contentType: 'application/json; charset=utf-8',
            }).then(function (res) {
                if(res.code == 0) console.log('push location success');
                else console.log('push location failed');
            });

        }
    }
    // get location
    var notificationGeoLocation = (function () {
        var savedLat = Cookies.get('posLat');
        var savedLon = Cookies.get('posLon');
        var _lat;
        var _long;

        var _geoFindMe = function() {
            if (!navigator.geolocation) return;
           commonGetLocation(_updatePositions, _errorOnLocation);
        };

        var _updatePushPositions = function(position) {
            var newLat = position.coords.latitude;
            var newLon = position.coords.longitude;
            savedLat = Cookies.get('posLat');
            savedLon = Cookies.get('posLon');

            if (newLat !== savedLat || newLon !== savedLon) {
                //Update input fields values
                _lat = newLat;
                _long = newLon;

                //Save new location to cookie
                Cookies.set('posLat', newLat, { path: '/'});
                Cookies.set('posLon', newLon, { path: '/'});
            }
            pushLocationNotify(_lat, _long);
            if(!position.fromIp){
                $(".location-error").remove();
            }
        };

        var _errorOnLocation = function(e) {
            console.log('get location failed!');
        };

        var init = function() {
            if(!check_iOSapp() && !check_androidApp()){
                if (navigator.permissions && navigator.geolocation) {
                    navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                            if(result.state === "prompt") {
                                _geoFindMe();
                                Cookies.set('locationPermission', 'prompt')
                            } else if (result.state === "granted") {
                                _geoFindMe();
                                Cookies.set('locationPermission', 'granted');
                            } else if (result.state === "denied") {
                                const ipaip = function() {
                                    $.ajax('https://ipapi.co/json/?key='+$("#ipapiSecret").val().toString())
                                    .then(
                                        function success(response) {
                                            if(response.latitude && response.longitude){
                                                _lat = response.latitude;
                                                _long = response.longitude;
                                            } else {
                                                Cookies.set('showLocationDenied', false, {path: '/'});
                                            }
                                        },
                                        function fail(data, status) {
                                            Cookies.set('showLocationDenied', false, {path: '/'});
                                        }
                                    );
                                }
                                $.ajax('/geoip/address').then((res) => {
                                    if(res && res.ll.length) {
                                        _lat = res.ll[0];
                                        _long = res.ll[1];
                                    } else {
                                        ipaip();
                                    }
                                })
                            }
                        });
                } else {
                    _geoFindMe();
                }
            }
        };

        return {
            init: init,
            updatePushPositions: _updatePushPositions
        };
    })();
    // geoLocation.init();
    window.notificationGeoLocation = notificationGeoLocation;
});
